<template lang="pug" src="./SearchUser.pug"></template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default{

    data: () => ({ 
        search: '',
        pagination: {},
        dataTableLoading: false,
        expanded: [],
        singleExpand: false,
    }),
    computed: {
        ...mapGetters(['emails']),
        headers() {
            return [
            { text: 'Promotor(a)', value: 'name' },
            { text: 'E-mail', value: 'email' },
            { text: 'Cadastrado com', value: 'authProvider' },
            { text: 'Código', value: 'code' },
            { text: 'Válido até', value: 'codeExpiry' },
            ]
        },

        totalEmails() {
            return this.emails.length
        }
    },
    methods: {
        ...mapActions(['searchUser']),
        doSearch() {
            this.searchUser( `?key=${this.search}` )
        },
        formatDate(isoString) {
            const date = new Date(isoString)
            const now = new Date()
            if (date < now) return 'Expirado'
            return date.toLocaleString('pt-BR', { hour: '2-digit', minute: '2-digit', second: '2-digit' })
        },
        translate(term) {
            let translated = ''
            switch (term) {
                case 'Send':
                    translated = 'Enviado'
                    break;
                case 'Delivery':
                    translated = 'Entregue'
                    break;                    
                case 'Open':
                    translated = 'Aberto'
                    break;
                default: 
                    break;
            }
            return translated
        },
        getStatusColor(type) {
            const colors = {
                Send: 'blue',
                Delivery: 'green',
                Open: 'orange'
            }
            return colors[type] || 'grey'
        },
        getStatusIcon(type) {
            const icons = {
            sent: 'mdi-email-send',
            received: 'mdi-email-check',
            opened: 'mdi-email-open'
            }
            return icons[type] || 'mdi-email-alert'
        }

        
     },
    mounted() { },
}

</script>
<style>

  /* CSS corretivo: no Safari Mobile a tabela desconfigurava após expandir algum item */
  tr.v-data-table__expanded__content{
    box-shadow: none !important;
    background-color: #F9F9F9;
  }

  table tbody .v-data-table__mobile-table-row{
    display: block !important;
  }
  div.correctExpandableMobile tr.v-data-table__expanded__content{
    display: block !important;
    width: 100% !important;
  }
  div.correctExpandableMobile tr.v-data-table__expanded__content td{
    display: table;
    width: 100% !important;
  }

</style>
